import "jquery-match-height";
// import "./_ga";
// import "./js/revolution/revolution-main";


// import "./revolution/_jquery.themepunch.tools.min";
// import "./revolution/_jquery.themepunch.revolution.min";

// import "./revolution/extensions/revolution.extension.slideanims.min.js";
// import "./revolution/extensions/revolution.extension.layeranimation.min.js";
// import "./revolution/extensions/revolution.extension.navigation.min.js";
// import "./revolution/extensions/revolution.extension.carousel.min.js";
// import "./revolution/extensions/revolution.extension.video.min.js";
// import "./revolution/extensions/revolution.extension.kenburn.min.js";

// import "./_plugins";
// import './_scripts';

jQuery(document).ready(function($) {

    // document.addEventListener("contextmenu", preventInteraction, true);
    $('img').each(preventImageDrag);
    
    $('img').each(preventImageRightClick);
    
    $('img').each(preventCaseStudyImageClick);
    
    $('#home-intro-trigger').on('click', function() {
        let $target = $($(this).data('target'));
        if($target.length > 0) {
            scrollToIntro($target);
        } else {
            console.error('Target does not exist.');
        }
    });
    
    
     function preventImageDrag() {
         $(this).on('mousedown', preventInteraction);
     }
      function preventInteraction(e) {
          e.preventDefault();
      }
    
      function preventImageRightClick() {
          $(this).on("contextmenu", preventInteraction);
      }
    
      function preventCaseStudyImageClick() {
        $('#fotoramaID').on("contextmenu", preventInteraction);
    }

    function scrollToIntro($target) {
        window.scrollTo({
            top : ($target.offset().top - 30),
            behavior : 'smooth'
        });
        if (!$target.hasClass('show')) {
            $target.collapse('show');
        } 
    }

    function disableAutoScrollOnDesktop() {
        let winWidth = jQuery(window).width();

        if (winWidth > 991) {
            return false;
        }
    }

    $("a[href='#']").on('click', disableAutoScrollOnDesktop );
    
    });